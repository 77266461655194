<template>
  <div>
    <v-dialog
      :fullscreen="fullScreen"
      v-model="show"
      :width="dialogWidth ? dialogWidth : '600'"
    >
      <v-card elevation="0">
        <v-toolbar dark color="primary" flat style="padding-right: 10px !important">
          <v-toolbar-title class="overline">{{ title }}</v-toolbar-title>
          <v-btn icon right absolute dark>
            <v-icon @click="fullScreen ? (fullScreen = false) : (fullScreen = true)">{{
              setIconScreen()
            }}</v-icon>
            <v-icon @click="show = false">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card elevation="0">
          <v-container>
            <div style="border: 1px grey solid; mardin: 10px; padding: 15px">
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    outlined
                    :items="customers"
                    v-model="customerId"
                    label="Cliente"
                    item-text="person.corporateName"
                    item-value="id"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                    outlined
                    :items="employees"
                    v-model="salesmanId"
                    label="Vendedor"
                    item-text="person.corporateName"
                    item-value="id"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-btn
                block
                :disabled="!customerId || !salesmanId"
                :loading="loadingAction"
                color="primary"
                @click="oneStage"
              >
                Continuar
              </v-btn>
            </div>
          </v-container>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import http from "@/services/http";
import Vue from "vue";

export default Vue.extend({
  props: ["visible", "dialogWidth"],
  data: () => ({
    title: "Nova Venda",
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false /* doesn't work with directive */,
    },
    fullScreen: false,
    search: "",
    loading: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    employees: [],
    type: "VENDA",
    stage: 1,
    customerId: null,
    salesmanId: null,
    saleId: null,
    customers: [],
    loadingAction: false,
  }),
  methods: {
    oneStage() {
      this.loadingAction = true;
      const payload = {
        customerId: this.customerId,
        salesmanId: this.salesmanId,
        type: this.type,
        stage: 2,
      };
      if (this.saleId) {
        http.put(`gerentor/sales/${this.saleId}`, payload).then((res) => {
          const { data } = res;
          this.stage = data.stage;
          //this.customerName = data?.customer?.person?.corporateName;
        });
      } else {
        http.post(`gerentor/sales`, payload).then((res) => {
          const { data } = res;
          this.$router.push(`/base/sale/${data.id}/edit`);
          this.saleId = data.id;
          this.stage = data.stage;
        });
      }
      this.loadingAction = false;
    },
    setIconScreen() {
      if (this.fullScreen) return "mdi-fullscreen-exit";
      else return "mdi-fullscreen";
    },
    getSalesman() {},
    getEmployees() {
      this.loading = true;
      http.get("gerentor/employees").then((data) => {
        this.employees = data.data;
        const user = localStorage.getItem("user");
        if (user !== null) {
          const userJson = JSON.parse(user);
          const userId = userJson.id;
          const salesman = this.employees.find((a) => a.userId === userId);
          if (salesman) {
            this.salesmanId = salesman.id;
          }
        }
        this.loading = false;
      });
      this.loading = false;
    },
    getCustomers() {
      this.loading = true;
      http.get("gerentor/customers").then(
        (data) => {
          this.customers = data.data;
          this.loading = false;
        },
        () => (this.loading = false)
      );
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  mounted() {
    this.getEmployees();
    this.getCustomers();
  },
});
</script>
<style scoped>
fieldset {
  width: 97%;
  height: 90%;
  margin: auto;
  border-radius: 4px;
  padding: 15px;
}
</style>
